<template>
  <section>
      <div class="content-header">
        <h2>Why should you create inclusive and brave learning spaces?</h2>
      </div>
      <div class="content-wrapper">
        <p>The research shows that students develop more resilience when they feel like they belong. Inclusive learning spaces support students whose socio-economic circumstances, physical disability or poverty has become a barrier to their educational success—and faculty have a key role to play in creating inclusive learning environments. </p>
        <p>In that regard, some researchers recommend replacing the notion of “safe” learning spaces with <strong class="bold">brave</strong> learning spaces, in order to encourage authentic conversations related to any issues of identity, oppression, power and privilege that may arise int eh physical or digital classroom. </p>
       <p>Specifically, the research highlights how students perceive “safe spaces” as places where faculty rule out conflict, whereas “brave spaces” allow all students and faculty alike to be vulnerable and exposed as they dissect controversial issues together. </p>
       <p>Ask yourself: Do you promote an illusion of safety in your classroom, or do you encourage your students to show courage in their learning? </p>
       <p class="lm ">Learn more</p>

       <ul>
        <li>Check out the essay, From Safe Spaces to Brave Places: A New Way to Frame Dialogue Around Diversity and Social Justice by Brian Arao and Kristi Clemens (Chapter 8 in the book, <em>The Art of Effective Facilitation</em> by L.M. Landreman). </li>
        <li>This article talks about the <a href="https://corescholar.libraries.wright.edu/cgi/viewcontent.cgi?article=1174&context=ejie" target="_blank">intersection of resilience and inclusion</a> (part of the <a href="http://network.bepress.com/education/disability-and-equity-in-education/?utm_campaign=PDFCoverPages&utm_medium=PDF&utm_source=corescholar.libraries.wright.edu%2Fejie%2Fvol3%2Fiss3%2F2" target="_blank">Disability and Equity in Education Commons</a>, and the <a href="http://network.bepress.com/education/special-education-and-teaching/?utm_campaign=PDFCoverPages&utm_medium=PDF&utm_source=corescholar.libraries.wright.edu%2Fejie%2Fvol3%2Fiss3%2F2" target="_blank">Special Education and Teaching Commons</a>). </li>
        <li>This article features <a href="https://journals.sagepub.com/doi/full/10.1177/00957984211001195" target="_blank">Black students’ voices</a> on the topic academic resilience.</li>
       </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
